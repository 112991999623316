/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  padding: 1rem 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  margin-right: 1rem; /* spacing between logo and links */
}

/* Responsive logo size using clamp */
.navbar-logo img {
  height: clamp(40px, 10vw, 70px);
  transition: transform 0.3s ease;
}

.navbar-logo img:hover {
  transform: scale(1.05);
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

/* Base: Fluid typography for nav links on very small devices */
.navbar-links li a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  white-space: nowrap;
  transition: color 0.3s ease;
  font-size: clamp(0.85rem, 1.5vw, 1.2rem);
}

.navbar-links li a:hover,
.navbar-links li a.active {
  color: #00E6FF; /* Neon Cyan */
}

/* Adjust spacing on small devices */
@media (max-width: 600px) {
  .navbar {
    padding: 0.75rem 1rem;
  }
  
  .navbar-links {
    gap: 1rem;
  }
}

/* For devices between 400px and 600px (e.g. larger phones like iPhone 15 Pro Max) */
@media (min-width: 400px) and (max-width: 600px) {
  .navbar-links li a {
    /* Increase the minimum and preferred size */
    font-size: clamp(1rem, 2vw, 1.4rem);
  }
}

/* For desktop and larger screens */
@media (min-width: 901px) {
  .navbar-links li a {
    /* Slightly larger base for desktops */
    font-size: clamp(1.1rem, 1.8vw, 1.4rem);
  }
}

/* Optional: For tablet-sized devices */
@media (max-width: 900px) and (min-width: 601px) {
  .navbar {
    padding: 0.85rem 1.5rem;
  }
  
  .navbar-links {
    gap: 1.2rem;
  }
}
