/* App.css */
:root {
  --primary-color: #003366;      /* deep blue */
  --secondary-color: #fda085;
  --accent-color: #cc0000;
  --background-color: #f9f9f9;
  --font-family: 'Roboto', sans-serif;
  --text-color: #333;
  --card-bg: #ffffff;
}

/* Fluid typography base */
html {
  font-size: clamp(14px, 2vw, 18px);
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
}

.app {
  text-align: center;
}

.page-container {
  padding: 0.2rem;
}

/* Global Container Card for Page Content */
.container-card {
  background: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  max-width: 800px;
  width: 90%;  /* ensure it shrinks on very small screens */
  margin: 2rem auto;
  text-align: left;
}

/* Global Typography */
h1, h2, h3 {
  color: var(--text-color);
  margin-bottom: 1rem;
}

p {
  color: var(--text-color);
  line-height: 1.6;
}

/* Optional: Adjust container spacing on tablets */
@media (max-width: 900px) {
  .container-card {
    padding: 1.5rem;
    margin: 1.5rem auto;
  }
}
