/* landing-page.css */

/* Landing Page Base */
.landing-page {
  padding: 1rem; /* Add some padding to avoid edge collisions */
}

.landing-page h1 {
  margin-bottom: 2rem;
  text-align: center;
  /* Fluid heading: adjust between 1.7rem and 2.2rem */
  font-size: clamp(1.7rem, 2.5vw, 2.2rem);
}

/* Landing page landscape video styling */
.landingpage-video-wrapper {
  margin-bottom: 2rem;
}

.landingpage-video-wrapper.landscape {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px; /* adjust as needed */
  margin: 0 auto 2rem;
  border: 4px solid #cc0000;
  border-radius: 12px;
  overflow: hidden;
  background: black;
}

.landingpage-video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Responsive Grid for Testimonials */
.video-grid {
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  /* Fluid columns: each card will be at least 250px and expand to fill space */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 0 1rem; /* Add horizontal padding to prevent edge overflow */
}

/* Container for each testimonial */
.testimonial-container {
  width: 100%;
  max-width: 350px; /* ensures it shrinks on small screens */
  margin: 0 auto; /* center each item */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Reserve space for multi-line text without shifting videos */
.testimonial-label {
  min-height: 3rem; /* adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.5rem;
}

/* Base video wrapper styling */
.video-wrapper {
  border: 4px solid #cc0000; /* Red border */
  border-radius: 12px;
  overflow: hidden;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Portrait videos: locked at 9:16 */
.video-wrapper.portrait {
  aspect-ratio: 9 / 16; 
  width: 100%;
}

/* Landscape videos (if needed in other places) */
.video-wrapper.landscape {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

/* Additional breakpoints for fine-tuning on very small devices */
@media (max-width: 480px) {
  .landing-page {
    padding: 0.5rem;
  }
  
  .landingpage-video-wrapper.landscape {
    border-width: 2px;
  }
}
