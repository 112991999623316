.donate-page {
  padding: 2rem;
  background-color: #fff; /* soft, warm background */
  color: #333;
}

.donate-page h1 {
  margin-bottom: 1rem;
}

.donate-button {
  background-color: #cc0000;
  color: #fff;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.donate-button:hover {
  background-color: #a30000;
}
