/* ContactPage.css */
.contact-page {
  min-height: 87.9vh;  /* Adjust if your navbar height changes */
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .contact-page {
    min-height: 80vh;
    padding: 10px;
  }
}

.contact-container {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  padding: 40px;
  max-width: 500px;
  width: 100%;
  margin: 0 1rem; /* Horizontal margin for small screens */
  text-align: center;
}

/* Fluid typography for contact headers */
.contact-container h1 {
  margin-bottom: 20px;
  font-size: clamp(1.8rem, 5vw, 2.5rem);
  color: #1a237e; /* Deep dark blue */
}

.contact-card h2 {
  margin-bottom: 15px;
  font-size: clamp(1.4rem, 4vw, 1.8rem);
  color: #000; /* Black */
}

/* Ensure general text stays black */
.contact-card p {
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  color: #000; /* Black for general text */
  margin: 10px 0;
}

/* Style only the phone number and address inside paragraphs */
.contact-card p .highlight {
  color: #1565c0; /* Dark blue */
  font-weight: bold;
  transition: color 0.3s ease;
}

.contact-card p .highlight:hover {
  color: #64b5f6; /* Light blue on hover */
}

/* Updated link colors */
.contact-card a {
  color: #0d47a1; /* Deep blue */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.contact-card a:hover {
  color: #64b5f6; /* Light blue */
}

/* Tablet adjustments: increase padding and adjust typography if needed */
@media (max-width: 900px) and (min-width: 601px) {
  .contact-container {
    padding: 30px;
  }
}
