/* about-page.css */

/* Container adjustments to avoid horizontal scrolling */
.about-page {
  width: 90%;
  max-width: 900px;
  margin: 1rem auto 2rem;
  text-align: left;
}

/* Headings with fluid typography */
.about-page h1 {
  /* Fluid size between 2rem and 2.5rem */
  font-size: clamp(2rem, 5vw, 2.5rem);
  margin-bottom: 1.5rem;
  text-align: center;
  color: var(--text-color, #333);
}

.about-page h2 {
  /* Fluid size between 1.6rem and 1.8rem */
  font-size: clamp(1.6rem, 4vw, 1.8rem);
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--text-color, #333);
}

/* Image Styling */
.about-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 12px;
  display: block;
  margin: 0 auto 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-image:hover {
  transform: scale(1.02);
}

/* Hero image margin */
.hero {
  margin-bottom: 2rem;
}

/* Content paragraphs with fluid sizing */
.about-content p {
  font-size: clamp(1rem, 2.5vw, 1.1rem);
  line-height: 1.6;
  color: var(--text-color, #444);
  margin-bottom: 1rem;
}

/* Image container for additional images */
.about-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0 1rem; /* prevent overflow on narrow devices */
}

/* Optional: Ensure the container-card style from global CSS is applied */
.container-card {
  background-color: var(--card-bg, #fff);
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  padding: 2rem;
}

/* Additional adjustments for very small devices */
@media (max-width: 480px) {
  .about-page {
    margin: 0.5rem auto;
  }
  
  .about-images {
    gap: 10px;
  }
}
